<template>
  <div>
    <AppTitulo titulo="Termos de Condições de Uso" />

    <div class="terms">
      <object :data="linkPdf" type="application/pdf" width="100%" height="1000px">
        <embed :src="linkPdf" type="application/pdf" width="100%" height="1000px" />
      </object>
    </div>
  </div>
</template>

<script>
import AppTitulo from '@/components/AppTitulo';

import { fileServer } from '@/utils/functions';

export default {
  name: 'TermsOfUse',
  components: {
    AppTitulo,
  },
  computed: {
    linkPdf() {
      return fileServer('termos.pdf#zoom=100&view=fitH', 'files');
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  padding: 15px;
  margin: 0.4rem 0;
}
</style>
